import React from 'react';
import styles from '../styles/fetchButton.module.css';

export default function FetchButton({ endpoint, data, onDataReceived, disabled, onFetchStart }) {
    const handleClick = async () => {
        try {
            if (disabled) return;
            onFetchStart(); // Call this function before making the request
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const res = await response.json();
            onDataReceived(res);
        } catch (error) {
            console.error('Error:', error);
            onDataReceived({ error: error.message });
        }
    };

    return (
        <button className={styles['button-generate']} onClick={handleClick} disabled={disabled}>GENERATE</button>
    );
}

import React, { useState } from 'react';

const WorldPPEmbed = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            {isLoading && <div className="spinner"></div>}
            <iframe
                title="Lesson: Fantasy Worlds (B1)"
                src="https://1drv.ms/p/c/d74998713cb7f3d6/IQMD4iRZO8BqR51Ebe-LprdiAU15IHSdiNfpFhnHGu4t0eY?em=2&amp;wdAr=1.7777777777777777"
                width="100%"
                height="100%"
                allowFullScreen
                frameborder="0"
                onLoad={() => setIsLoading(false)}>
                This is an embedded Microsoft Office presentation, powered by Office.
            </iframe>
        </div>
    );
};

export default WorldPPEmbed;
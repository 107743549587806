import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FetchButton from '../components/FetchButton';
import styles from '../styles/monsterCreator.module.css';

const MonsterCreator = () => {
    const pageTitle = "Monster Maker";
    const url = process.env.REACT_APP_API_URL + "/maker/monster";

    // List of monsters and their characteristics
    const monsters = ["Dragon", "Chimera", "Cyclops", "Frankenstein", "Golem", "Gorgon", "Hydra", "Kraken", "Minotaur", "Ogre", "Vampire", "Werewolf", "Zombie", "Jiangshi", "Yaoguai"];
    const monsterCharacteristics = ["Large Eyes", "Sharp Fangs", "Long Horns", "Big Nose", "Slim", "Chubby", "Muscular", "Furry", "Wings", "Claws", "Spikes", "Tail"];

    // Handles the selected monster
    const [selectedMonster, setSelectedMonster] = useState('');
    const [customMonsterType, setCustomMonsterType] = useState('');
    const handleMonsterSelection = (event, monster) => {
        // Check if the event target is a button and if the selected monster is the same as the monster
        if (event.target.tagName.toLowerCase() === 'button' && selectedMonster === monster) {
            // If it is, clear the selected monster
            setSelectedMonster('');
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customMonsterType state to the value of the text input
            setCustomMonsterType(event.target.value);
            // Also, set the selected monster to the current monster (Other)
            setSelectedMonster(monster);
        }
        // If the event target is neither a button with the selected monster nor a text input
        else {
            // Set the selected monster to the current monster
            setSelectedMonster(monster);
        }
    };

    // Handles the selected characteristics
    const [characteristics, setCharacteristics] = useState([]);
    const [customMonsterCharacteristics, setCustomMonsterCharacteristics] = useState('');
    const handleChange = (event) => {
        // Check if the event target is a checkbox and if it's checked
        if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'checkbox' && event.target.checked) {
            // If it is, add the value of the checkbox to the characteristics state
            setCharacteristics(prev => [...prev, event.target.value]);
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customMonsterCharacteristics state to the value of the text input
            setCustomMonsterCharacteristics(event.target.value);
            // Also, check the checkbox with id 'describeMore'
            const target = document.querySelector('#describeMore');
            target.checked = true;
            if (!characteristics.includes(target.value)) {
                setCharacteristics(prev => [...prev, target.value]);
            }
        }
        // If the event target is neither a checked checkbox nor a text input
        else {
            // Remove the value of the event target from the characteristics state
            setCharacteristics(prev => prev.filter(char => char !== event.target.value));
        }
    };

    // New function to handle "Remove All" button click
    const handleRemoveAll = () => {
        // Reset the characteristics state
        setCharacteristics([]);

        // Reset the customMonsterCharacteristics state
        setCustomMonsterCharacteristics('');

        // Uncheck all checkboxes
        const checkboxes = document.querySelectorAll(`.${styles['attribute-checkbox']} input[type="checkbox"]`);
        checkboxes.forEach(checkbox => checkbox.checked = false);
    };

    // State variable for the spinner
    const [isLoading, setIsLoading] = useState(false);

    // State variable for the image URL
    const [monsterImage1, setMonsterImage1] = useState('');
    const [revisedPrompt, setRevisedPrompt] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const handleDataReceived = (data) => {
        if (data.error) {
            // Prompt a popup message displaying the error message
            alert(data.error);
            setIsLoading(false);
            return;
        }
        setMonsterImage1(data.image_url);
        setRevisedPrompt(data.revised_prompt);
        setIsVisible(true);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    // Monster information to be submitted to the server
    const monsterInfo = {
        monsterType: selectedMonster,
        characteristics: characteristics,
        customMonsterType: customMonsterType,
        customMonsterCharacteristics: customMonsterCharacteristics,
    };

    return (
        <div className="content-body">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={styles['monster-page-title']}>Monster Maker</div>
            <h2>Select a Monster:</h2>
            <div className={styles['monster-select-group']}>
                {monsters.map((monster, index) => (
                    <button key={index} type="button" className={`button ${selectedMonster === monster ? styles['selected'] : ''}`} onClick={(event) => handleMonsterSelection(event, monster)}>{monster}</button>
                ))}
            </div>
            <div className={styles['monster-select-group']}>
                <button key="Other" type="button" className={`button ${selectedMonster === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleMonsterSelection(event, "Other")}>Other</button>
                <input type="text" value={customMonsterType} name="monsterName" placeholder="Type in the name of the monster" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleMonsterSelection(event, "Other")} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: '10px' }}>Describe the Monster:</h2>
                <button onClick={handleRemoveAll}>Remove All Options</button>
            </div>
            <div className={styles['monster-characteristics-group']}>
                {monsterCharacteristics.map((attribute, index) => (
                    <div key={index} className={styles['attribute-checkbox']}>
                        <input type="checkbox" id={attribute} name={attribute} value={attribute} onChange={handleChange} />
                        <label htmlFor={attribute}>{attribute}</label>
                    </div>
                ))}
            </div>
            <div className={styles['monster-characteristics-group']}>
                <div className={styles['attribute-checkbox']}>
                    <input type="checkbox" id="describeMore" name="describeMore" value="describeMore" onChange={handleChange} />
                    <label htmlFor="describeMore">Describe more</label>
                </div>
                <input type="text" value={customMonsterCharacteristics} name="detailedDescription" placeholder="Describe the monster in more detail" style={{ gridColumn: '2 / -1' }} onChange={handleChange} />
            </div>
            <FetchButton
                endpoint={url}
                data={monsterInfo}
                onDataReceived={handleDataReceived}
                disabled={isLoading}
                onFetchStart={() => setIsLoading(true)}
            />
            <div className={styles['monster-pics']} style={{ display: isVisible ? 'grid' : 'none' }}>
                <div className={styles['monster-pic']}>
                    {monsterImage1 && <img src={monsterImage1} alt="Generated Monster" onLoad={() => handleImageLoad()} />}
                </div>
                <div className={styles['monster-description']}>
                    {revisedPrompt && <p>{revisedPrompt}</p>}
                </div>
            </div>
            {isLoading && <div className="spinner"></div>}
        </div>
    );
};

export default MonsterCreator;

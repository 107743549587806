import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FetchButton from '../components/FetchButton';
import styles from '../styles/storyCreator.module.css';

const StoryCreator = () => {
    const pageTitle = "Story Maker";
    const url = process.env.REACT_APP_API_URL + "/maker/story";

    const englishLevels = ["A1 Beginner", "B1 Intermediate"];
    const numberOfPages = [4, 6, 8, 10, 12];
    const storyThemes = [
        "Acceptance and Diversity",
        "Courage and Perseverance",
        "Discovery and Adventure",
        "Family and Honesty",
        "Friendship and Kindness",
        "Imagination and Creativity",
    ];

    // Handles the selected English level
    const [selectedEnglishLevel, setSelectedEnglishLevel] = useState('');
    const handleEnglishLevelSelection = (event, level) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedEnglishLevel === level) {
            setSelectedEnglishLevel('');
        } else {
            setSelectedEnglishLevel(level);
        }
    };

    // Handles the selected number of pages
    const [selectedNumberOfPages, setSelectedNumberOfPages] = useState('');
    const [customNumberOfPages, setCustomNumberOfPages] = useState('');
    const handleNumberOfPagesSelection = (event, pages) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedNumberOfPages === pages) {
            setSelectedNumberOfPages('');
        } else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            const value = event.target.value.trim();
            if (!value) {
                setCustomNumberOfPages(value);
                setSelectedNumberOfPages(pages);
                return;
            }
            if (!/^\d+$/.test(value)) {
                alert('Please enter a number');
                return;
            }
            const numberValue = parseInt(value, 10);
            if (numberValue < 1 || numberValue > 20) {
                alert('Please enter a number between 1 and 20');
                return;
            }
            setCustomNumberOfPages(event.target.value);
            setSelectedNumberOfPages(pages);
        } else {
            setSelectedNumberOfPages(pages);
        }
    };

    // Handles the selected story theme
    const [selectedStoryTheme, setSelectedStoryTheme] = useState('');
    const [customStoryTheme, setCustomStoryTheme] = useState('');
    const handleStoryThemeSelection = (event, theme) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedStoryTheme === theme) {
            setSelectedStoryTheme('');
        } else if (event.target.tagName.toLowerCase() === 'textarea') {
            setCustomStoryTheme(event.target.value);
            setSelectedStoryTheme(theme);
        } else {
            setSelectedStoryTheme(theme);
        }
    };

    const [storyHeroes, setStoryHeroes] = useState('');
    const handleStoryHeroesChange = (event) => {
        setStoryHeroes(event.target.value);
    };

    const [storyMonsters, setStoryMonsters] = useState('');
    const handleStoryMonstersChange = (event) => {
        setStoryMonsters(event.target.value);
    };

    const [storyWorld, setStoryWorld] = useState('');
    const handleStoryWorldChange = (event) => {
        setStoryWorld(event.target.value);
    };

    const randomize = (array) => {
        return array[Math.floor(Math.random() * array.length)];
    };
    const handleRandomize = () => {
        handleClearAll();
        setSelectedEnglishLevel(randomize(englishLevels));
        setSelectedNumberOfPages(randomize(numberOfPages));
        setSelectedStoryTheme(randomize(storyThemes));
    };

    const handleClearAll = () => {
        setSelectedEnglishLevel('');
        setSelectedNumberOfPages('');
        setCustomNumberOfPages('');
        setSelectedStoryTheme('');
        setCustomStoryTheme('');
        setStoryHeroes('');
        setStoryMonsters('');
        setStoryWorld('');
    };

    // State variable for the spinner
    const [isLoading, setIsLoading] = useState(false);

    // State variable for the image URL
    const [textOutput, setTextOutput] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const handleDataReceived = (data) => {
        if (data.error) {
            // Prompt a popup message displaying the error message
            alert(data.error);
            setIsLoading(false);
            return;
        }
        setTextOutput(data.story);
        setIsVisible(true);
        setIsLoading(false);
    };

    // Information to be submitted to the server
    const storyInfo = {
        englishLevel: selectedEnglishLevel,
        storyTheme: selectedStoryTheme === "Other" ? customStoryTheme : selectedStoryTheme,
        numberOfPages: selectedNumberOfPages === "Other" ? customNumberOfPages : selectedNumberOfPages,
        heroesDescription: storyHeroes,
        monstersDescription: storyMonsters,
        worldDescription: storyWorld,
    };

    return (
        <div className="content-body">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={styles['page-title']}>{pageTitle}</div>
            <div className={styles['action-buttons']}>
                <button onClick={handleRandomize}>Random Story</button>
                <button onClick={handleClearAll}>Clear All</button>
            </div>
            <h2>Create a fairy tale story book and your own "happy ever after."</h2>
            <h2>Select the English Level:</h2>
            <div className={styles['section-buttons']}>
                {englishLevels.map((level, index) => (
                    <button key={index} type="button" className={`button ${selectedEnglishLevel === level ? styles.selected : ''}`} onClick={(event) => handleEnglishLevelSelection(event, level)}>{level}</button>
                ))}
            </div>
            <h2>Select the number of pages in the book:</h2>
            <div className={styles['section-buttons']} style={{ gridTemplateColumns: 'repeat(8, 1fr)' }}>
                {numberOfPages.map((pages, index) => (
                    <button key={index} type="button" className={`button ${selectedNumberOfPages === pages ? styles.selected : ''}`} onClick={(event) => handleNumberOfPagesSelection(event, pages)}>{pages}</button>
                ))}
                <button type="button" className={`button ${selectedNumberOfPages === "Other" ? styles.selected : ''}`} onClick={(event) => handleNumberOfPagesSelection(event, "Other")}>Other</button>
                <input type="text" value={customNumberOfPages} name="numberOfPages" placeholder="Type in #" style={{ gridColumn: '7 / -1' }} onChange={(event) => handleNumberOfPagesSelection(event, "Other")} />
            </div>
            <h2>Select the Theme of the Story:</h2>
            <div className={styles['section-buttons']}>
                {storyThemes.map((theme, index) => (
                    <button key={index} type="button" className={`button ${selectedStoryTheme === theme ? styles.selected : ''}`} onClick={(event) => handleStoryThemeSelection(event, theme)}>{theme}</button>
                ))}
            </div>
            <div className={styles['section-buttons']}>
                <button type="button" className={`button ${selectedStoryTheme === "Other" ? styles.selected : ''}`} onClick={(event) => handleStoryThemeSelection(event, "Other")}>Other</button>
            </div>
            <div className={styles['section-large-input-text']}>
                <textarea value={customStoryTheme} name="themeName" placeholder="Type in another story theme or short description of the story." onChange={(event) => handleStoryThemeSelection(event, "Other")} />
            </div>
            <h2>Describe your Hero or Heroes:</h2>
            <div className={styles['section-large-input-text']}>
                <textarea placeholder="Describe your hero or heroes here." value={storyHeroes} onChange={handleStoryHeroesChange} />
            </div>
            <h2>Describe the Creatures of Monsters in Your Story:</h2>
            <div className={styles['section-large-input-text']}>
                <textarea placeholder="Describe the creatures or monsters here." value={storyMonsters} onChange={handleStoryMonstersChange} />
            </div>
            <h2>Describe the Fairy Tale World where the Story Takes Place:</h2>
            <div className={styles['section-large-input-text']}>
                <textarea placeholder="Describe the fairy tale world here." value={storyWorld} onChange={handleStoryWorldChange} />
            </div>
            <FetchButton
                endpoint={url}
                data={storyInfo}
                onDataReceived={handleDataReceived}
                disabled={isLoading}
                onFetchStart={() => setIsLoading(true)}
            />
            <div className={styles['generated-content']} style={{ display: isVisible ? 'grid' : 'none' }}>
                <textarea value={textOutput} readOnly />
            </div>
            {isLoading && <div className="spinner"></div>}
        </div>
    );
};

export default StoryCreator;
import React, { useState } from 'react';

const MonsterPPEmbed = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
        }}>
            {isLoading && <div className="spinner"></div>}
            <iframe
                title="Lesson: Monsters (B1)"
                src="https://onedrive.live.com/embed?resid=D74998713CB7F3D6%21337&amp;authkey=!AOXTLmOpUMuHTIs&amp;em=2&amp;wdAr=1.7777777777777777"
                width="100%"
                height="100%"
                allowFullScreen
                frameborder="0"
                onLoad={() => setIsLoading(false)}>
                This is an embedded Microsoft Office presentation, powered by Office.
            </iframe>
        </div>
    );
};

export default MonsterPPEmbed;
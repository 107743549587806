import React, { Component } from 'react';
import '../styles/welcome.css';

class Welcome extends Component {
    render() {
        return (
            <div>
                <h1 className='page-title'>Welcome to Kinetic 101!</h1>
                <div className='button-container'>
                    <button className='button' onClick={() => { window.location.href = '/monster-presentation'; }}>Lesson: Monsters (B1)</button>
                    <button className='button' onClick={() => { window.location.href = '/monster-maker'; }}>Monster Maker</button>
                </div>
                <div className='button-container'>
                    <button className='button' onClick={() => { window.location.href = '/hero-presentation'; }}>Lesson: Heroes (B1)</button>
                    <button className='button' onClick={() => { window.location.href = '/hero-maker'; }}>Hero Maker</button>
                </div>
                <div className='button-container'>
                    <button className='button' onClick={() => { window.location.href = '/world-map-presentation'; }}>Lesson: Fantasy Worlds (B1)</button>
                    <button className='button' onClick={() => { window.location.href = '/world-maker'; }}>World Maker</button>
                </div>
                <div className='button-container'>
                    <button className='button' onClick={() => { window.location.href = '/world-map-design-presentation'; }}>Resource: Fantasy World Map Design</button>
                </div>
                <div className='button-container'>
                    <button className='button' onClick={() => { window.location.href = '/story-presentation'; }}>Lesson: Fairy Tale Stories (B1)</button>
                    <button className='button' onClick={() => { window.location.href = '/story-maker'; }}>Story Maker</button>
                </div>
            </div>
        );
    }
}

export default Welcome;

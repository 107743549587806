import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FetchButton from '../components/FetchButton';
import styles from '../styles/heroCreator.module.css';

const HeroCreator = () => {
    const pageTitle = "Hero Maker";
    const url = process.env.REACT_APP_API_URL + "/maker/hero";

    // List of heroes and their characteristics
    const types = ["Archer", "Bard", "Craftsman", "Explorer", "Healer", "Knight", "Pirate", "Prince/Princess", "Time Traveler", "Wizard", "Samurai", "Ninja", "Geisha", "Shaolin Monk", "Scholar"];
    const races = ["Human", "Elf", "Dwarf", "Panda", "Turtle", "Automaton", "Centaur", "Fairy", "Mermaid/Merman", "Satyr"];
    const genders = ["Male", "Female"];
    const skinColors = ["Pale Skin", "Dark Skin", "Blue Skin", "Green Skin", "Red Skin"];
    const bodyTypes = ["Tall", "Short", "Muscular", "Slender", "Stocky"];
    const actionPoses = ["Standing", "Sitting", "Jumping", "Running", "Attacking"];
    const headGears = ["Eye Patch", "Eyeglasses", "Horned Helm", "Kabuki Mask", "Royal Crown"];
    const outfits = ["Flowing Robes", "Leather Tunic", "Magical Cloak", "Plate Armor", "Tuxedo"];
    const weapons = [
        "Magical Key", "Compass", "Glowing Lantern", "Harp", "Spell Book",
        "Magical Wand", "Royal Scepter", "Flintlock Pistol", "Gauntlets", "Katana",
        "Magical Staff", "Bow and Arrows", "Sword and Shield", "Giant Hammer", "Trident"
    ];

    // Handles the hero type
    const [selectedType, setSelectedType] = useState('');
    const [customType, setCustomType] = useState('');
    const handleTypeSelection = (event, type) => {
        // Check if the event target is a button and if the selected type is the same as the current type
        if (event.target.tagName.toLowerCase() === 'button' && selectedType === type) {
            // If it is, clear the selected type
            setSelectedType('');
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customType state to the value of the text input
            setCustomType(event.target.value);
            // Also, set the selected type to the current type (Other)
            setSelectedType(type);
        }
        // If the event target is neither a button with the selected type nor a text input
        else {
            // Set the selected type to the current type
            setSelectedType(type);
        }
    };

    // Handles the hero's race
    const [selectedRace, setSelectedRace] = useState('');
    const [customRace, setCustomRace] = useState('');
    const handleRaceSelection = (event, race) => {
        // Check if the event target is a button and if the selected race is the same as the current race
        if (event.target.tagName.toLowerCase() === 'button' && selectedRace === race) {
            // If it is, clear the selected race
            setSelectedRace('');
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customRace state to the value of the text input
            setCustomRace(event.target.value);
            // Also, set the selected race to the current race (Other)
            setSelectedRace(race);
        }
        // If the event target is neither a button with the selected race nor a text input
        else {
            // Set the selected race to the current race
            setSelectedRace(race);
        }
    };

    // Handles the hero's gender
    const [selectedGender, setSelectedGender] = useState('');
    const handleGenderSelection = (event, gender) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedGender === gender) {
            setSelectedGender('');
        } else {
            setSelectedGender(gender);
        }
    };

    // Handles the hero's skin color
    const [selectedSkinColor, setSelectedSkinColor] = useState('');
    const handleSkinColorSelection = (event, skinColor) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedSkinColor === skinColor) {
            setSelectedSkinColor('');
        } else {
            setSelectedSkinColor(skinColor);
        }
    };

    // Handles the hero's body type
    const [selectedBodyType, setSelectedBodyType] = useState('');
    const handleBodyTypeSelection = (event, bodyType) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedBodyType === bodyType) {
            setSelectedBodyType('');
        } else {
            setSelectedBodyType(bodyType);
        }
    };

    // Handles the hero's action pose
    const [selectedActionPose, setSelectedActionPose] = useState('');
    const handleActionPoseSelection = (event, actionPose) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedActionPose === actionPose) {
            setSelectedActionPose('');
        } else {
            setSelectedActionPose(actionPose);
        }
    };

    const [selectedCustomFeatures, setSelectedCustomFeatures] = useState('');
    const [customFeatures, setCustomFeatures] = useState('');
    const handleCustomFeaturesSelection = (event, customFeatures) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedCustomFeatures === customFeatures) {
            setSelectedCustomFeatures('');
        } else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            setCustomFeatures(event.target.value);
            setSelectedCustomFeatures(customFeatures);
        } else {
            setSelectedCustomFeatures(customFeatures);
        }
    };

    // Handles the hero's head gear
    const [selectedHeadGear, setSelectedHeadGear] = useState('');
    const handleHeadGearSelection = (event, headGear) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedHeadGear === headGear) {
            setSelectedHeadGear('');
        } else {
            setSelectedHeadGear(headGear);
        }
    };

    // Handles the hero's outfits
    const [selectedOutfit, setSelectedOutfit] = useState('');
    const handleOutfitSelection = (event, outfit) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedOutfit === outfit) {
            setSelectedOutfit('');
        } else {
            setSelectedOutfit(outfit);
        }
    };

    const [selectedCustomOutfits, setSelectedCustomOutfits] = useState('');
    const [customOutfits, setCustomOutfits] = useState('');
    const handleCustomOutfitsSelection = (event, customOutfits) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedCustomOutfits === customOutfits) {
            setSelectedCustomOutfits('');
        } else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            setCustomOutfits(event.target.value);
            setSelectedCustomOutfits(customOutfits);
        } else {
            setSelectedCustomOutfits(customOutfits);
        }
    };

    // Handles the hero's weapon
    const [selectedWeapon, setSelectedWeapon] = useState('');
    const handleWeaponSelection = (event, weapon) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedWeapon === weapon) {
            setSelectedWeapon('');
        } else {
            setSelectedWeapon(weapon);
        }
    };

    const [selectedCustomWeapon, setSelectedCustomWeapon] = useState('');
    const [customWeapon, setCustomWeapon] = useState('');
    const handleCustomWeaponSelection = (event, customWeapon) => {
        if (event.target.tagName.toLowerCase() === 'button' && selectedCustomWeapon === customWeapon) {
            setSelectedCustomWeapon('');
        } else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            setCustomWeapon(event.target.value);
            setSelectedCustomWeapon(customWeapon);
        } else {
            setSelectedCustomWeapon(customWeapon);
        }
    };

    const randomize = (array) => {
        return array[Math.floor(Math.random() * array.length)];
    };
    const handleRandomize = () => {
        setSelectedType(randomize(types));
        setSelectedRace(randomize(races));
        setSelectedGender(randomize(genders));
        setSelectedSkinColor(randomize(skinColors));
        setSelectedBodyType(randomize(bodyTypes));
        setSelectedActionPose(randomize(actionPoses));
        setSelectedHeadGear(randomize(headGears));
        setSelectedOutfit(randomize(outfits));
        setSelectedWeapon(randomize(weapons));
    };

    const handleClearAll = () => {
        setSelectedType('');
        setCustomType('');
        setSelectedRace('');
        setCustomRace('');
        setSelectedGender('');
        setSelectedSkinColor('');
        setSelectedBodyType('');
        setSelectedActionPose('');
        setSelectedCustomFeatures('');
        setCustomFeatures('');
        setSelectedHeadGear('');
        setSelectedOutfit('');
        setSelectedCustomOutfits('');
        setCustomOutfits('');
        setSelectedWeapon('');
        setCustomWeapon('');
        setSelectedCustomWeapon('');
        setIsVisible(false);
    };

    // State variable for the spinner
    const [isLoading, setIsLoading] = useState(false);

    // State variable for the image URL
    const [generatedImage, setGeneratedImage] = useState('');
    const [revisedPrompt, setRevisedPrompt] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const handleDataReceived = (data) => {
        if (data.error) {
            // Prompt a popup message displaying the error message
            alert(data.error);
            setIsLoading(false);
            return;
        }
        setGeneratedImage(data.image_url);
        setRevisedPrompt(data.revised_prompt);
        setIsVisible(true);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    // Hero information to be submitted to the server
    const heroInfo = {
        type: selectedType,
        customType: customType,
        race: selectedRace,
        customRace: customRace,
        gender: selectedGender,
        skinColor: selectedSkinColor,
        bodyType: selectedBodyType,
        actionPose: selectedActionPose,
        selectedCustomFeatures: selectedCustomFeatures,
        customFeatures: customFeatures,
        headGear: selectedHeadGear,
        outfit: selectedOutfit,
        selectedCustomOutfits: selectedCustomOutfits,
        customOutfits: customOutfits,
        weapon: selectedWeapon,
        customWeapon: customWeapon,
        selectedCustomWeapon: selectedCustomWeapon,
    };

    return (
        <div className="content-body">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={styles['hero-page-title']}>Hero Maker</div>
            <div className={styles['action-buttons']}>
                <button onClick={handleRandomize}>Random Hero</button>
                <button onClick={handleClearAll}>Clear All</button>
            </div>
            <h2>Select a Hero Type:</h2>
            <div className={styles['type-select-group']}>
                {types.map((type, index) => (
                    <button key={index} type="button" className={`button ${selectedType === type ? styles['selected'] : ''}`} onClick={(event) => handleTypeSelection(event, type)}>{type}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                <button key="Other" type="button" className={`button ${selectedType === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleTypeSelection(event, "Other")}>Other</button>
                <input type="text" value={customType} name="typeName" placeholder="Type in the hero type" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleTypeSelection(event, "Other")} />
            </div>
            <h2>Select the Hero's Race:</h2>
            <div className={styles['type-select-group']}>
                {races.map((race, index) => (
                    <button key={index} type="button" className={`button ${selectedRace === race ? styles['selected'] : ''}`} onClick={(event) => handleRaceSelection(event, race)}>{race}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                <button key="Other" type="button" className={`button ${selectedRace === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleRaceSelection(event, "Other")}>Other</button>
                <input type="text" value={customRace} name="raceName" placeholder="Type in the hero's race" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleRaceSelection(event, "Other")} />
            </div>
            <h2>Describe the Hero:</h2>
            <div className={styles['type-select-group']}>
                {genders.map((gender, index) => (
                    <button key={index} type="button" className={`button ${selectedGender === gender ? styles['selected'] : ''}`} onClick={(event) => handleGenderSelection(event, gender)}>{gender}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                {skinColors.map((skinColor, index) => (
                    <button key={index} type="button" className={`button ${selectedSkinColor === skinColor ? styles['selected'] : ''}`} onClick={(event) => handleSkinColorSelection(event, skinColor)}>{skinColor}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                {bodyTypes.map((bodyType, index) => (
                    <button key={index} type="button" className={`button ${selectedBodyType === bodyType ? styles['selected'] : ''}`} onClick={(event) => handleBodyTypeSelection(event, bodyType)}>{bodyType}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                {actionPoses.map((actionPose, index) => (
                    <button key={index} type="button" className={`button ${selectedActionPose === actionPose ? styles['selected'] : ''}`} onClick={(event) => handleActionPoseSelection(event, actionPose)}>{actionPose}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                <button key="Other" type="button" className={`button ${selectedCustomFeatures === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleCustomFeaturesSelection(event, "Other")}>Other</button>
                <input type="text" value={customFeatures} name="customFeaturesName" placeholder="Type in other hero features" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleCustomFeaturesSelection(event, "Other")} />
            </div>
            <h2>Style Your Hero:</h2>
            <div className={styles['type-select-group']}>
                {headGears.map((headGear, index) => (
                    <button key={index} type="button" className={`button ${selectedHeadGear === headGear ? styles['selected'] : ''}`} onClick={(event) => handleHeadGearSelection(event, headGear)}>{headGear}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                {outfits.map((outfit, index) => (
                    <button key={index} type="button" className={`button ${selectedOutfit === outfit ? styles['selected'] : ''}`} onClick={(event) => handleOutfitSelection(event, outfit)}>{outfit}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                <button key="Other" type="button" className={`button ${selectedCustomOutfits === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleCustomOutfitsSelection(event, "Other")}>Other</button>
                <input type="text" value={customOutfits} name="customOutfitsName" placeholder="Type in other hero outfits" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleCustomOutfitsSelection(event, "Other")} />
            </div>
            <h2>Equip Your Hero:</h2>
            <div className={styles['type-select-group']}>
                {weapons.map((weapon, index) => (
                    <button key={index} type="button" className={`button ${selectedWeapon === weapon ? styles['selected'] : ''}`} onClick={(event) => handleWeaponSelection(event, weapon)}>{weapon}</button>
                ))}
            </div>
            <div className={styles['type-select-group']}>
                <button key="Other" type="button" className={`button ${selectedCustomWeapon === "Other" ? styles['selected'] : ''}`} onClick={(event) => handleCustomWeaponSelection(event, "Other")}>Other</button>
                <input type="text" value={customWeapon} name="customWeaponName" placeholder="Type in the hero's weapon" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleCustomWeaponSelection(event, "Other")} />
            </div>
            <FetchButton
                endpoint={url}
                data={heroInfo}
                onDataReceived={handleDataReceived}
                disabled={isLoading}
                onFetchStart={() => setIsLoading(true)}
            />
            <div className={styles['generated-pics']} style={{ display: isVisible ? 'grid' : 'none' }}>
                <div className={styles['generated-pic']}>
                    {generatedImage && <img src={generatedImage} alt="Generated Hero" onLoad={() => handleImageLoad()} />}
                </div>
                <div className={styles['generated-description']}>
                    {revisedPrompt && <p>{revisedPrompt}</p>}
                </div>
            </div>
            {isLoading && <div className="spinner"></div>}
        </div>
    );
};

export default HeroCreator;

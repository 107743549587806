import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './pages/Welcome';
import HeroCreator from './pages/HeroCreator';
import HeroPresentation from './pages/HeroCreatorPresentation';
import MonsterCreator from './pages/MonsterCreator';
import MonsterPresentation from './pages/MonsterCreatorPresentation';
import WorldCreator from './pages/WorldCreator';
import WorldMapPresentation from './pages/WorldMapCreatorPresentation';
import WorldMapDesignPresentation from './pages/WorldMapDesignPresentation';
import StoryCreator from './pages/StoryCreator';
import StoryPresentation from './pages/StoryCreatorPresentation';
import './styles/fairy_tales.css';

function App() {
  return (
    <Router>
      <div>
        {/* Other components like Navbar can go here */}
        <Routes>
          {/* Define other routes here */}
          <Route path="*" element={<Welcome />} />
          <Route path="/hero-maker" element={<HeroCreator />} />
          <Route path="/hero-presentation" element={<HeroPresentation />} />
          <Route path="/monster-maker" element={<MonsterCreator />} />
          <Route path="/monster-presentation" element={<MonsterPresentation />} />
          <Route path="/world-maker" element={<WorldCreator />} />
          <Route path="/world-map-presentation" element={<WorldMapPresentation />} />
          <Route path="/world-map-design-presentation" element={<WorldMapDesignPresentation />} />
          <Route path="/story-maker" element={<StoryCreator />} />
          <Route path="/story-presentation" element={<StoryPresentation />} />
          {/* Add a default route if you like, using Route without path or with path="*" */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FetchButton from '../components/FetchButton';
import styles from '../styles/worldCreator.module.css';

const WorldCreator = () => {
    const pageTitle = "World Maker";
    const url = process.env.REACT_APP_API_URL + "/maker/world";

    const worlds = ["Enchanted Forest", "Frozen Wonderland", "Desert Wasteland", "Lava Fields", "Hidden Valley", "Underwater Kingdom", "Sky Kingdom", "Celestial Realm", "Bamboo Forest", "Terracotta Kingdom"];
    const locations = ["Cave Interior", "Floating Island", "Lakeside", "Meadow", "Mountaintop", "Ancient Ruins", "Castle Interior", "City Marketplace", "Temple Interior", "Village"];
    const elements = ["Clouds", "Lake", "Mountains", "Rainbow", "Waterfall", "Giant Trees", "Wildflowers", "Lava", "Mist", "Snow", "Bridge", "Cobblestone Roads", "Giant Statues", "Stone Towers", "Windmills", "Birds", "Fairies", "Fireflies", "Pandas", "Unicorns"];

    // Handles the selected world
    const [selectedWorld, setSelectedWorld] = useState('');
    const [customWorld, setCustomWorld] = useState('');
    const handleWorldSelection = (event, world) => {
        // Check if the event target is a button and if the selected world is the same as the world
        if (event.target.tagName.toLowerCase() === 'button' && selectedWorld === world) {
            // If it is, clear the selected world
            setSelectedWorld('');
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customWorld state to the value of the text input
            setCustomWorld(event.target.value);
            // Also, set the selected world to the current world (Other)
            setSelectedWorld(world);
        }
        // If the event target is neither a button with the selected world nor a text input
        else {
            // Set the selected world to the current world
            setSelectedWorld(world);
        }
    };

    // Handles the selected location
    const [selectedLocation, setSelectedLocation] = useState('');
    const [customLocation, setCustomLocation] = useState('');
    const handleLocationSelection = (event, location) => {
        // Check if the event target is a button and if the selected location is the same as the location
        if (event.target.tagName.toLowerCase() === 'button' && selectedLocation === location) {
            // If it is, clear the selected location
            setSelectedLocation('');
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customLocation state to the value of the text input
            setCustomLocation(event.target.value);
            // Also, set the selected location to the current location (Other)
            setSelectedLocation(location);
        }
        // If the event target is neither a button with the selected location nor a text input
        else {
            // Set the selected location to the current location
            setSelectedLocation(location);
        }
    };

    // Handles the selected elements
    const [selectedElements, setSelectedElements] = useState([]);
    const [customElements, setCustomElements] = useState('');
    const handleChange = (event) => {
        // Check if the event target is a checkbox and if it's checked
        if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'checkbox' && event.target.checked) {
            // If it is, add the value of the checkbox to the selectedElements state
            setSelectedElements(prev => [...prev, event.target.value]);
        }
        // Check if the event target is a text input
        else if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'text') {
            // If it is, set the customElements state to the value of the text input
            setCustomElements(event.target.value);
            // Also, check the checkbox with id 'describeMore'
            const target = document.querySelector('#describeMore');
            target.checked = true;
            if (!selectedElements.includes(target.value)) {
                setSelectedElements(prev => [...prev, target.value]);
            }
        }
        // If the event target is neither a checked checkbox nor a text input
        else {
            // Remove the value of the event target from the selectedElements state
            setSelectedElements(prev => prev.filter(elem => elem !== event.target.value));
        }
    };

    // Handle "Remove All" button click
    const handleRemoveAll = () => {
        // Reset the selectedLocation state
        setSelectedElements([]);

        // Reset the customLocation state
        setCustomElements('');

        // Uncheck all checkboxes
        const checkboxes = document.querySelectorAll(`.${styles['custom-checkbox']} input[type="checkbox"]`);
        checkboxes.forEach(checkbox => checkbox.checked = false);
    };

    // State variable for the spinner
    const [isLoading, setIsLoading] = useState(false);

    // State variable for the image URL
    const [generatedImage, setGeneratedImage] = useState('');
    const [revisedPrompt, setRevisedPrompt] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const handleDataReceived = (data) => {
        if (data.error) {
            // Prompt a popup message displaying the error message
            alert(data.error);
            setIsLoading(false);
            return;
        }
        setGeneratedImage(data.image_url);
        setRevisedPrompt(data.revised_prompt);
        setIsVisible(true);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    // Information to be submitted to the server
    const worldInfo = {
        selectedWorld,
        customWorld,
        selectedLocation,
        customLocation,
        selectedElements,
        customElements,
    };

    return (
        <div className="content-body">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={styles['creator-page-title']}>{pageTitle}</div>
            <h2>Select the Fantasy World:</h2>
            <div className={styles['section-buttons']}>
                {worlds.map((world, index) => (
                    <button key={index} type="button" className={`button ${selectedWorld === world ? styles.selected : ''}`} onClick={(event) => handleWorldSelection(event, world)}>{world}</button>
                ))}
            </div>
            <div className={styles['section-buttons']}>
                <button key="Other" type="button" className={`button ${selectedWorld === "Other" ? styles.selected : ''}`} onClick={(event) => handleWorldSelection(event, "Other")}>Other</button>
                <input type="text" value={customWorld} name="worldName" placeholder="Describe another world" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleWorldSelection(event, "Other")} />
            </div>
            <h2>Select the Location:</h2>
            <div className={styles['section-buttons']}>
                {locations.map((location, index) => (
                    <button key={index} type="button" className={`button ${selectedLocation === location ? styles.selected : ''}`} onClick={(event) => handleLocationSelection(event, location)}>{location}</button>
                ))}
            </div>
            <div className={styles['section-buttons']}>
                <button key="Other" type="button" className={`button ${selectedLocation === "Other" ? styles.selected : ''}`} onClick={(event) => handleLocationSelection(event, "Other")}>Other</button>
                <input type="text" value={customLocation} name="locationName" placeholder="Describe another location" style={{ gridColumn: '2 / -1' }} onChange={(event) => handleLocationSelection(event, "Other")} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: '10px' }}>Include the following:</h2>
                <button onClick={handleRemoveAll}>Remove All Options</button>
            </div>
            <div className={styles['section-checkboxes']}>
                {elements.map((element, index) => (
                    <div key={index} className={styles['custom-checkbox']}>
                        <input type="checkbox" id={element} name={element} value={element} onChange={handleChange} />
                        <label htmlFor={element}>{element}</label>
                    </div>
                ))}
            </div>
            <div className={styles['section-checkboxes']}>
                <div className={styles['custom-checkbox']}>
                    <input type="checkbox" id="describeMore" name="describeMore" value="describeMore" onChange={handleChange} />
                    <label htmlFor="describeMore">Describe more</label>
                </div>
                <input type="text" value={customElements} name="detailedDescription" placeholder="Add other things into your world" style={{ gridColumn: '2 / -1' }} onChange={handleChange} />
            </div>
            <FetchButton
                endpoint={url}
                data={worldInfo}
                onDataReceived={handleDataReceived}
                disabled={isLoading}
                onFetchStart={() => setIsLoading(true)}
            />
            <div className={styles['generated-pics']} style={{ display: isVisible ? 'grid' : 'none' }}>
                <div className={styles['generated-pic']}>
                    {generatedImage && <img src={generatedImage} alt="Generated World" onLoad={() => handleImageLoad()} />}
                </div>
                <div className={styles['generated-description']}>
                    {revisedPrompt && <p>{revisedPrompt}</p>}
                </div>
            </div>
            {isLoading && <div className="spinner"></div>}
        </div>
    );
};

export default WorldCreator;
